import Image from '@activebrands/core-web/components/Image';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import { styled } from '@activebrands/core-web/libs/styletron';
import OverlayHeader from 'components/overlays/OverlayHeader';

const Wrapper = styled('div', {
    width: 'var(--width-overlays)',
    height: '100%',
});

const InnerWrapper = styled('div', {
    alignItems: 'space-between',
    background: 'var(--color-bg)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    position: 'relative',
    width: '100%',
});

interface CompareLensesOverlayProps {
    compareText: string;
    mediaUrl: string;
}

const CompareLensesOverlay = ({ compareText, mediaUrl }: CompareLensesOverlayProps) => (
    <Wrapper>
        <InnerWrapper>
            <OverlayHeader headingText={compareText} overlayId="compare-lenses" />
            <ScrollableBox scrollDirection='vertical' $style={{ maxHeight: 'calc(100dvh - var(--height-header) - 40px)', flexGrow: 1 }}>
                <Image
                    $style={{ margin: '0 auto', width: '100%', height: '100%' }}
                    src={{
                        url: mediaUrl,
                        width: [420, 840],
                    }}
                    sizes="420px"
                />
            </ScrollableBox>
        </InnerWrapper>
    </Wrapper>
);

export default CompareLensesOverlay;
